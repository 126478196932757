export enum RouteName {
  Dashboard = 'Dashboard',
  Documents = 'Documents',
  ForgotPassword = 'ForgotPassword',
  Home = 'Home',
  Invoices = 'Invoices',
  Login = 'Login',
  PersonalData = 'PersonalData',
  PreferredDeliveryMethod = 'PreferredDeliveryMethod',
  Profile = 'Profile',
  Register = 'Register',
  SecurityAndAccount = 'SecurityAndAccount',
  SetInitialPassword = 'SetInitialPassword',
  StatusHealth = 'StatusHealth',
  UpdateForgottenPassword = 'UpdateForgottenPassword',
  VerifyRegistration = 'VerifyRegistration'
}
