<script lang="ts" setup>
import { useHead } from '@vueuse/head'
import { onMounted } from 'vue'
import { useI18n } from 'vue-i18n'

import { useRefreshTokenMutation } from '@/graphql/default/composables'
import { useUserStore } from '@/stores/user'
import { TokenType } from '~/@types/generated/default/graphql-schema-types'

const userStore = useUserStore()
const { setUserAccessToken } = userStore
const { t } = useI18n()

useHead({
  title: t('app.meta-title')
})

onMounted(async () => {
  try {
    const { mutate } = useRefreshTokenMutation()
    const result = await mutate({
      type: TokenType.Patient
    })
    setUserAccessToken(result?.data?.refreshToken.accessToken)
  } catch (_) {
    // empty
  }
})
</script>

<template>
  <RouterView />
</template>
