import '@/assets/css/main.css'

import { ApolloClients } from '@vue/apollo-composable'
import { createHead } from '@vueuse/head'
import { createPinia } from 'pinia'
import piniaPersist from 'pinia-plugin-persist'
import { createApp, h, provide } from 'vue'

import useApolloClients from '@/apollo'
import { useI18n } from '@/i18n'

import App from './App.vue'
import router from './router'

const head = createHead()

const app = createApp({
  render: () => h(App),
  setup() {
    provide(ApolloClients, useApolloClients())
  }
})

app.use(useI18n())
app.use(head)

const pinia = createPinia()
pinia.use(piniaPersist)
app.use(pinia)

app.use(router)
app.mount('#app')
