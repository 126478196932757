import { createRouter, createWebHistory } from 'vue-router'

import { baseUrl } from '@/config'
import { authenticatedNotAllowedGuard } from '@/guards/authenticatedNotAllowedGuard'
import { authenticationRequiredGuard } from '@/guards/authenticationRequiredGuard'
import { RouteName } from '@/router/RouteName'

const router = createRouter({
  history: createWebHistory(baseUrl),
  routes: [
    {
      component: () => import(/* webpackChunkName: "home" */ '../views/HomeView.vue'),
      name: RouteName.Home,
      path: '/'
    },
    {
      beforeEnter: authenticatedNotAllowedGuard,
      component: () => import(/* webpackChunkName: "login" */ '../views/LoginView.vue'),
      meta: { authenticatedNotAllowed: true },
      name: RouteName.Login,
      path: '/anmelden'
    },
    {
      beforeEnter: authenticatedNotAllowedGuard,
      component: () => import(/* webpackChunkName: "register" */ '../views/RegisterView.vue'),
      meta: { authenticatedNotAllowed: true },
      name: RouteName.Register,
      path: '/registrieren'
    },
    {
      beforeEnter: authenticatedNotAllowedGuard,
      component: () =>
        import(/* webpackChunkName: "verify-registration" */ '../views/VerifyRegistrationView.vue'),
      meta: { authenticatedNotAllowed: true },
      name: RouteName.VerifyRegistration,
      path: '/verifizieren'
    },
    {
      beforeEnter: authenticatedNotAllowedGuard,
      component: () =>
        import(/* webpackChunkName: "forgot-password" */ '../views/ForgotPasswordView.vue'),
      meta: { authenticatedNotAllowed: true },
      name: RouteName.ForgotPassword,
      path: '/passwort-vergessen'
    },
    {
      beforeEnter: authenticatedNotAllowedGuard,
      component: () =>
        import(
          /* webpackChunkName: "update-forgotten-password" */ '../views/UpdateForgottenPasswordView.vue'
        ),
      meta: { authenticatedNotAllowed: true },
      name: RouteName.UpdateForgottenPassword,
      path: '/passwort-vergeben'
    },
    {
      component: () =>
        import(
          /* webpackChunkName: "set-initial-password" */ '../views/SetInitialPasswordView.vue'
        ),
      name: RouteName.SetInitialPassword,
      path: '/passwort-setzen'
    },
    {
      component: () => import(/* webpackChunkName: 'view-status' */ '../views/StatusView.vue'),
      name: RouteName.StatusHealth,
      // route used for health probing and status page
      path: '/status/health'
    },
    {
      children: [
        {
          component: () => import(/* webpackChunkName: 'dashboard' */ '../views/DashboardView.vue'),
          name: RouteName.Dashboard,
          path: 'dashboard'
        },
        {
          component: () => import(/* webpackChunkName: 'dashboard' */ '../views/InvoicesView.vue'),
          name: RouteName.Invoices,
          path: 'rechnungen'
        },
        {
          children: [
            {
              component: () =>
                import(/* webpackChunkName: 'documents' */ '../views/PersonalDataView.vue'),
              name: RouteName.PersonalData,
              path: 'persoenliche-daten'
            },
            {
              component: () =>
                import(
                  /* webpackChunkName: 'documents' */ '../views/PreferredDeliveryMethodView.vue'
                ),
              name: RouteName.PreferredDeliveryMethod,
              path: 'bevorzugte-zustellart'
            },
            {
              component: () =>
                import(/* webpackChunkName: 'documents' */ '../views/SecurityAndAccountView.vue'),
              name: RouteName.SecurityAndAccount,
              path: 'sicherheit-und-account'
            }
          ],
          component: () => import(/* webpackChunkName: 'profile' */ '../views/ProfileView.vue'),
          name: RouteName.Profile,
          path: 'profil',
          redirect: (to) => ({ name: RouteName.PersonalData, query: to.query })
        }
      ],
      meta: { authenticationRequired: true },
      path: '/mein-bereich',
      redirect: (to) => ({ name: RouteName.Dashboard, query: to.query })
    }
  ],
  scrollBehavior(to, from) {
    if (to.path === from.path) {
      return
    }
    return { top: 0 }
  }
})

router.beforeEach(authenticationRequiredGuard)

export default router
