import { storeToRefs } from 'pinia'
import type { RouteLocationNormalized } from 'vue-router'

import { RouteName } from '@/router/RouteName'
import { useUserStore } from '@/stores/user'

export const authenticatedNotAllowedGuard = (to: RouteLocationNormalized) => {
  const userStore = useUserStore()
  const { isUserAuthenticated } = storeToRefs(userStore)
  if (isUserAuthenticated.value) {
    return { name: RouteName.Dashboard, ...(to.query && { query: to.query }) }
  }
}
