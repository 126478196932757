import { provideApolloClient } from '@vue/apollo-composable'
import { storeToRefs } from 'pinia'
import type { NavigationGuardNext, RouteLocationNormalized } from 'vue-router'

import { defaultClient } from '@/apollo/clients/default'
import { useRefreshTokenMutation } from '@/graphql/default/composables'
import { RouteName } from '@/router/RouteName'
import { useUserStore } from '@/stores/user'
import { TokenType } from '~/@types/generated/default/graphql-schema-types'

export const authenticationRequiredGuard = async (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) => {
  const userStore = useUserStore()
  const { isUserAuthenticated } = storeToRefs(userStore)
  const authenticationIsRequired = to.matched.some((record) => record.meta.authenticationRequired)
  const authenticatedIsNotAllowed = to.matched.some((record) => record.meta.authenticatedNotAllowed)
  if (
    (authenticationIsRequired && !isUserAuthenticated.value) ||
    (!authenticationIsRequired && authenticatedIsNotAllowed)
  ) {
    const { setUserAccessToken } = userStore
    provideApolloClient(defaultClient)
    try {
      const { mutate } = useRefreshTokenMutation()
      const result = await mutate({
        type: TokenType.Patient
      })
      setUserAccessToken(result?.data?.refreshToken.accessToken)
    } catch (_) {
      if (authenticationIsRequired) {
        next({
          name: RouteName.Login,
          query: { redirect: encodeURIComponent(to.fullPath) }
        })
      }
    }
  }
  next()
}
