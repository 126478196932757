import { createI18n } from 'vue-i18n'

import deDatetimeFormats from './de/datetimeFormats.json'
import deMessages from './de/messages.json'
import deNumberFormats from './de/numberFormats.json'

const messages = {
  de: deMessages
}

const numberFormats = {
  de: deNumberFormats
}

const datetimeFormats = {
  de: deDatetimeFormats
}

export function useI18n() {
  return createI18n({
    datetimeFormats,
    fallbackLocale: 'de',
    legacy: false,
    locale: 'de',
    messages,
    numberFormats,
    warnHtmlInMessage: 'off'
  })
}
