// @ts-nocheck
import gql from 'graphql-tag';
import * as VueApolloComposable from '@vue/apollo-composable';
import * as VueCompositionApi from '@vue/composition-api';
export type ReactiveFunction<TParam> = () => TParam;
export type ChangePatientPasswordMutationVariables = Exact<{
  input: ChangePatientPasswordInput;
}>;


export type ChangePatientPasswordMutation = { __typename?: 'Mutation', changePatientPassword: { __typename?: 'Patient', email: string } };

export type ForgotPasswordMutationVariables = Exact<{
  input: ForgotPasswordInput;
}>;


export type ForgotPasswordMutation = { __typename?: 'Mutation', forgotPassword: boolean };

export type LinkPatientMutationVariables = Exact<{
  input: LinkPatientInput;
}>;


export type LinkPatientMutation = { __typename?: 'Mutation', linkPatient: boolean };

export type LoginMutationVariables = Exact<{
  input: LoginPatientInput;
}>;


export type LoginMutation = { __typename?: 'Mutation', loginPatient: { __typename?: 'TokenPayload', accessToken: string, accessType?: TokenScope | null } };

export type LogoutPatientMutationVariables = Exact<{ [key: string]: never; }>;


export type LogoutPatientMutation = { __typename?: 'Mutation', logoutPatient?: boolean | null };

export type MarkInvoiceAsSeenMutationVariables = Exact<{
  input: MarkInvoiceAsSeenInput;
}>;


export type MarkInvoiceAsSeenMutation = { __typename?: 'Mutation', markInvoiceAsSeen?: { __typename?: 'Invoice', _id: string } | null };

export type PatientQueryVariables = Exact<{ [key: string]: never; }>;


export type PatientQuery = { __typename?: 'Query', patient: { __typename?: 'Patient', email: string, firstName?: string | null, lastName?: string | null, birthday?: any | null, city?: string | null, street?: string | null, phone?: string | null, postalCode?: string | null, _id: string, preferredDeliveryMethod: PreferredDeliveryMethod } };

export type PatientInvoicesQueryVariables = Exact<{
  limit: Scalars['Int'];
  skip: Scalars['Int'];
}>;


export type PatientInvoicesQuery = { __typename?: 'Query', patientInvoices: Array<{ __typename?: 'Invoice', _id: string, invoicenr?: string | null, dueAt?: any | null, billedAt?: any | null, amount?: number | null, financial_state?: string | null, recipient?: string | null, patient?: string | null, seenAt?: any | null, address?: { __typename?: 'InvoiceAddress', street?: string | null, zipCode?: string | null, city?: string | null } | null, node: { __typename?: 'Node', name: string }, metadata?: { __typename?: 'MetaData', iban: string, sender_address_one: string, sender_address_two: string, sender_address_three: string } | null } | null> };

export type PatientUpdateMutationVariables = Exact<{
  input: PatientUpdateInput;
}>;


export type PatientUpdateMutation = { __typename?: 'Mutation', updatePatient: { __typename?: 'Patient', _id: string, postalCode?: string | null, city?: string | null, street?: string | null, firstName?: string | null, lastName?: string | null, phone?: string | null, birthday?: any | null } };

export type RefreshTokenMutationVariables = Exact<{
  type: TokenType;
}>;


export type RefreshTokenMutation = { __typename?: 'Mutation', refreshToken: { __typename?: 'TokenPayload', accessToken: string } };

export type RegisterPatientMutationVariables = Exact<{
  input: PatientRegisterInput;
}>;


export type RegisterPatientMutation = { __typename?: 'Mutation', registerPatient: { __typename?: 'Patient', _id: string } };

export type UpdateForgottenPasswordMutationVariables = Exact<{
  input: UpdateForgottenPasswordInput;
}>;


export type UpdateForgottenPasswordMutation = { __typename?: 'Mutation', updateForgottenPassword: boolean };

export type UpdatePatientPasswordMutationVariables = Exact<{
  input: PatientPasswordInput;
}>;


export type UpdatePatientPasswordMutation = { __typename?: 'Mutation', updatePatientPassword: { __typename?: 'Patient', _id: string } };

export type UpdatePreferredDeliveryMethodMutationVariables = Exact<{
  input: PreferredDeliveryMethodInput;
}>;


export type UpdatePreferredDeliveryMethodMutation = { __typename?: 'Mutation', updatePreferredDeliveryMethod: { __typename?: 'Patient', _id: string, preferredDeliveryMethod: PreferredDeliveryMethod } };

export type ValidateIndividualMutationVariables = Exact<{
  input: ValidateIndividualInput;
}>;


export type ValidateIndividualMutation = { __typename?: 'Mutation', validateIndividual: boolean };

export type VerifyPatientMutationVariables = Exact<{
  input: VerifyPatientInput;
}>;


export type VerifyPatientMutation = { __typename?: 'Mutation', verifyPatient: { __typename?: 'TokenPayload', accessToken: string } };


export const ChangePatientPasswordDocument = gql`
    mutation ChangePatientPassword($input: ChangePatientPasswordInput!) {
  changePatientPassword(input: $input) {
    email
  }
}
    `;

/**
 * __useChangePatientPasswordMutation__
 *
 * To run a mutation, you first call `useChangePatientPasswordMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useChangePatientPasswordMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useChangePatientPasswordMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useChangePatientPasswordMutation(options: VueApolloComposable.UseMutationOptions<ChangePatientPasswordMutation, ChangePatientPasswordMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<ChangePatientPasswordMutation, ChangePatientPasswordMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<ChangePatientPasswordMutation, ChangePatientPasswordMutationVariables>(ChangePatientPasswordDocument, options);
}
export type ChangePatientPasswordMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<ChangePatientPasswordMutation, ChangePatientPasswordMutationVariables>;
export const ForgotPasswordDocument = gql`
    mutation ForgotPassword($input: ForgotPasswordInput!) {
  forgotPassword(input: $input)
}
    `;

/**
 * __useForgotPasswordMutation__
 *
 * To run a mutation, you first call `useForgotPasswordMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useForgotPasswordMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useForgotPasswordMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useForgotPasswordMutation(options: VueApolloComposable.UseMutationOptions<ForgotPasswordMutation, ForgotPasswordMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<ForgotPasswordMutation, ForgotPasswordMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<ForgotPasswordMutation, ForgotPasswordMutationVariables>(ForgotPasswordDocument, options);
}
export type ForgotPasswordMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<ForgotPasswordMutation, ForgotPasswordMutationVariables>;
export const LinkPatientDocument = gql`
    mutation LinkPatient($input: LinkPatientInput!) {
  linkPatient(input: $input)
}
    `;

/**
 * __useLinkPatientMutation__
 *
 * To run a mutation, you first call `useLinkPatientMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useLinkPatientMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useLinkPatientMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useLinkPatientMutation(options: VueApolloComposable.UseMutationOptions<LinkPatientMutation, LinkPatientMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<LinkPatientMutation, LinkPatientMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<LinkPatientMutation, LinkPatientMutationVariables>(LinkPatientDocument, options);
}
export type LinkPatientMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<LinkPatientMutation, LinkPatientMutationVariables>;
export const LoginDocument = gql`
    mutation login($input: LoginPatientInput!) {
  loginPatient(input: $input) {
    accessToken
    accessType
  }
}
    `;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useLoginMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useLoginMutation(options: VueApolloComposable.UseMutationOptions<LoginMutation, LoginMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<LoginMutation, LoginMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, options);
}
export type LoginMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<LoginMutation, LoginMutationVariables>;
export const LogoutPatientDocument = gql`
    mutation logoutPatient {
  logoutPatient
}
    `;

/**
 * __useLogoutPatientMutation__
 *
 * To run a mutation, you first call `useLogoutPatientMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useLogoutPatientMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useLogoutPatientMutation();
 */
export function useLogoutPatientMutation(options: VueApolloComposable.UseMutationOptions<LogoutPatientMutation, LogoutPatientMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<LogoutPatientMutation, LogoutPatientMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<LogoutPatientMutation, LogoutPatientMutationVariables>(LogoutPatientDocument, options);
}
export type LogoutPatientMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<LogoutPatientMutation, LogoutPatientMutationVariables>;
export const MarkInvoiceAsSeenDocument = gql`
    mutation MarkInvoiceAsSeen($input: MarkInvoiceAsSeenInput!) {
  markInvoiceAsSeen(input: $input) {
    _id
  }
}
    `;

/**
 * __useMarkInvoiceAsSeenMutation__
 *
 * To run a mutation, you first call `useMarkInvoiceAsSeenMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useMarkInvoiceAsSeenMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useMarkInvoiceAsSeenMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useMarkInvoiceAsSeenMutation(options: VueApolloComposable.UseMutationOptions<MarkInvoiceAsSeenMutation, MarkInvoiceAsSeenMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<MarkInvoiceAsSeenMutation, MarkInvoiceAsSeenMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<MarkInvoiceAsSeenMutation, MarkInvoiceAsSeenMutationVariables>(MarkInvoiceAsSeenDocument, options);
}
export type MarkInvoiceAsSeenMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<MarkInvoiceAsSeenMutation, MarkInvoiceAsSeenMutationVariables>;
export const PatientDocument = gql`
    query patient {
  patient {
    email
    firstName
    lastName
    email
    birthday
    city
    street
    phone
    postalCode
    _id
    preferredDeliveryMethod
  }
}
    `;

/**
 * __usePatientQuery__
 *
 * To run a query within a Vue component, call `usePatientQuery` and pass it any options that fit your needs.
 * When your component renders, `usePatientQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = usePatientQuery();
 */
export function usePatientQuery(options: VueApolloComposable.UseQueryOptions<PatientQuery, PatientQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<PatientQuery, PatientQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<PatientQuery, PatientQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<PatientQuery, PatientQueryVariables>(PatientDocument, {}, options);
}
export function usePatientLazyQuery(options: VueApolloComposable.UseQueryOptions<PatientQuery, PatientQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<PatientQuery, PatientQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<PatientQuery, PatientQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<PatientQuery, PatientQueryVariables>(PatientDocument, {}, options);
}
export type PatientQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<PatientQuery, PatientQueryVariables>;
export const PatientInvoicesDocument = gql`
    query PatientInvoices($limit: Int!, $skip: Int!) {
  patientInvoices(limit: $limit, skip: $skip) {
    _id
    invoicenr
    dueAt
    billedAt
    amount
    financial_state
    recipient
    patient
    address {
      street
      zipCode
      city
    }
    node {
      name
    }
    metadata {
      iban
      sender_address_one
      sender_address_two
      sender_address_three
    }
    seenAt
  }
}
    `;

/**
 * __usePatientInvoicesQuery__
 *
 * To run a query within a Vue component, call `usePatientInvoicesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePatientInvoicesQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = usePatientInvoicesQuery({
 *   limit: // value for 'limit'
 *   skip: // value for 'skip'
 * });
 */
export function usePatientInvoicesQuery(variables: PatientInvoicesQueryVariables | VueCompositionApi.Ref<PatientInvoicesQueryVariables> | ReactiveFunction<PatientInvoicesQueryVariables>, options: VueApolloComposable.UseQueryOptions<PatientInvoicesQuery, PatientInvoicesQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<PatientInvoicesQuery, PatientInvoicesQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<PatientInvoicesQuery, PatientInvoicesQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<PatientInvoicesQuery, PatientInvoicesQueryVariables>(PatientInvoicesDocument, variables, options);
}
export function usePatientInvoicesLazyQuery(variables: PatientInvoicesQueryVariables | VueCompositionApi.Ref<PatientInvoicesQueryVariables> | ReactiveFunction<PatientInvoicesQueryVariables>, options: VueApolloComposable.UseQueryOptions<PatientInvoicesQuery, PatientInvoicesQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<PatientInvoicesQuery, PatientInvoicesQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<PatientInvoicesQuery, PatientInvoicesQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<PatientInvoicesQuery, PatientInvoicesQueryVariables>(PatientInvoicesDocument, variables, options);
}
export type PatientInvoicesQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<PatientInvoicesQuery, PatientInvoicesQueryVariables>;
export const PatientUpdateDocument = gql`
    mutation patientUpdate($input: PatientUpdateInput!) {
  updatePatient(input: $input) {
    _id
    postalCode
    city
    street
    firstName
    lastName
    phone
    birthday
  }
}
    `;

/**
 * __usePatientUpdateMutation__
 *
 * To run a mutation, you first call `usePatientUpdateMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `usePatientUpdateMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = usePatientUpdateMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function usePatientUpdateMutation(options: VueApolloComposable.UseMutationOptions<PatientUpdateMutation, PatientUpdateMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<PatientUpdateMutation, PatientUpdateMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<PatientUpdateMutation, PatientUpdateMutationVariables>(PatientUpdateDocument, options);
}
export type PatientUpdateMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<PatientUpdateMutation, PatientUpdateMutationVariables>;
export const RefreshTokenDocument = gql`
    mutation refreshToken($type: TokenType!) {
  refreshToken(type: $type) {
    accessToken
  }
}
    `;

/**
 * __useRefreshTokenMutation__
 *
 * To run a mutation, you first call `useRefreshTokenMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useRefreshTokenMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useRefreshTokenMutation({
 *   variables: {
 *     type: // value for 'type'
 *   },
 * });
 */
export function useRefreshTokenMutation(options: VueApolloComposable.UseMutationOptions<RefreshTokenMutation, RefreshTokenMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<RefreshTokenMutation, RefreshTokenMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<RefreshTokenMutation, RefreshTokenMutationVariables>(RefreshTokenDocument, options);
}
export type RefreshTokenMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<RefreshTokenMutation, RefreshTokenMutationVariables>;
export const RegisterPatientDocument = gql`
    mutation registerPatient($input: PatientRegisterInput!) {
  registerPatient(input: $input) {
    _id
  }
}
    `;

/**
 * __useRegisterPatientMutation__
 *
 * To run a mutation, you first call `useRegisterPatientMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useRegisterPatientMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useRegisterPatientMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useRegisterPatientMutation(options: VueApolloComposable.UseMutationOptions<RegisterPatientMutation, RegisterPatientMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<RegisterPatientMutation, RegisterPatientMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<RegisterPatientMutation, RegisterPatientMutationVariables>(RegisterPatientDocument, options);
}
export type RegisterPatientMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<RegisterPatientMutation, RegisterPatientMutationVariables>;
export const UpdateForgottenPasswordDocument = gql`
    mutation UpdateForgottenPassword($input: UpdateForgottenPasswordInput!) {
  updateForgottenPassword(input: $input)
}
    `;

/**
 * __useUpdateForgottenPasswordMutation__
 *
 * To run a mutation, you first call `useUpdateForgottenPasswordMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdateForgottenPasswordMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdateForgottenPasswordMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useUpdateForgottenPasswordMutation(options: VueApolloComposable.UseMutationOptions<UpdateForgottenPasswordMutation, UpdateForgottenPasswordMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<UpdateForgottenPasswordMutation, UpdateForgottenPasswordMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<UpdateForgottenPasswordMutation, UpdateForgottenPasswordMutationVariables>(UpdateForgottenPasswordDocument, options);
}
export type UpdateForgottenPasswordMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<UpdateForgottenPasswordMutation, UpdateForgottenPasswordMutationVariables>;
export const UpdatePatientPasswordDocument = gql`
    mutation UpdatePatientPassword($input: PatientPasswordInput!) {
  updatePatientPassword(input: $input) {
    _id
  }
}
    `;

/**
 * __useUpdatePatientPasswordMutation__
 *
 * To run a mutation, you first call `useUpdatePatientPasswordMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePatientPasswordMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdatePatientPasswordMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePatientPasswordMutation(options: VueApolloComposable.UseMutationOptions<UpdatePatientPasswordMutation, UpdatePatientPasswordMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<UpdatePatientPasswordMutation, UpdatePatientPasswordMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<UpdatePatientPasswordMutation, UpdatePatientPasswordMutationVariables>(UpdatePatientPasswordDocument, options);
}
export type UpdatePatientPasswordMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<UpdatePatientPasswordMutation, UpdatePatientPasswordMutationVariables>;
export const UpdatePreferredDeliveryMethodDocument = gql`
    mutation UpdatePreferredDeliveryMethod($input: PreferredDeliveryMethodInput!) {
  updatePreferredDeliveryMethod(input: $input) {
    _id
    preferredDeliveryMethod
  }
}
    `;

/**
 * __useUpdatePreferredDeliveryMethodMutation__
 *
 * To run a mutation, you first call `useUpdatePreferredDeliveryMethodMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePreferredDeliveryMethodMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdatePreferredDeliveryMethodMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePreferredDeliveryMethodMutation(options: VueApolloComposable.UseMutationOptions<UpdatePreferredDeliveryMethodMutation, UpdatePreferredDeliveryMethodMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<UpdatePreferredDeliveryMethodMutation, UpdatePreferredDeliveryMethodMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<UpdatePreferredDeliveryMethodMutation, UpdatePreferredDeliveryMethodMutationVariables>(UpdatePreferredDeliveryMethodDocument, options);
}
export type UpdatePreferredDeliveryMethodMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<UpdatePreferredDeliveryMethodMutation, UpdatePreferredDeliveryMethodMutationVariables>;
export const ValidateIndividualDocument = gql`
    mutation ValidateIndividual($input: ValidateIndividualInput!) {
  validateIndividual(input: $input)
}
    `;

/**
 * __useValidateIndividualMutation__
 *
 * To run a mutation, you first call `useValidateIndividualMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useValidateIndividualMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useValidateIndividualMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useValidateIndividualMutation(options: VueApolloComposable.UseMutationOptions<ValidateIndividualMutation, ValidateIndividualMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<ValidateIndividualMutation, ValidateIndividualMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<ValidateIndividualMutation, ValidateIndividualMutationVariables>(ValidateIndividualDocument, options);
}
export type ValidateIndividualMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<ValidateIndividualMutation, ValidateIndividualMutationVariables>;
export const VerifyPatientDocument = gql`
    mutation verifyPatient($input: VerifyPatientInput!) {
  verifyPatient(input: $input) {
    accessToken
  }
}
    `;

/**
 * __useVerifyPatientMutation__
 *
 * To run a mutation, you first call `useVerifyPatientMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useVerifyPatientMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useVerifyPatientMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useVerifyPatientMutation(options: VueApolloComposable.UseMutationOptions<VerifyPatientMutation, VerifyPatientMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<VerifyPatientMutation, VerifyPatientMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<VerifyPatientMutation, VerifyPatientMutationVariables>(VerifyPatientDocument, options);
}
export type VerifyPatientMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<VerifyPatientMutation, VerifyPatientMutationVariables>;