import { defineStore } from 'pinia'
import { computed, ref } from 'vue'

export const useUserStore = defineStore('user', () => {
  const accessToken = ref<string>()
  const isUserAuthenticated = computed<boolean>(() => Boolean(accessToken.value))

  function setUserAccessToken(token?: string) {
    if (token) accessToken.value = token
  }

  function unsetUserAccessToken() {
    accessToken.value = undefined
  }

  return {
    accessToken,
    isUserAuthenticated,
    setUserAccessToken,
    unsetUserAccessToken
  }
})
