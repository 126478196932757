export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: string;
  GermanDate: string;
  GermanDateOnly: string;
  GraphQLBuffer: any;
  JSON: object;
  Upload: any;
};

export type AssignmentResult = {
  __typename?: 'AssignmentResult';
  invoice?: Maybe<Invoice>;
  transaction?: Maybe<Transaction>;
};

export type BankDetails = {
  __typename?: 'BankDetails';
  account_holder?: Maybe<Scalars['String']>;
  bic?: Maybe<Scalars['String']>;
  iban?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type BankDetailsI = {
  account_holder?: InputMaybe<Scalars['String']>;
  bic?: InputMaybe<Scalars['String']>;
  iban?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

export type Billing = {
  __typename?: 'Billing';
  address?: Maybe<BillingContact>;
  bank?: Maybe<BankDetails>;
  contractCancelledAt?: Maybe<Scalars['GermanDateOnly']>;
  contractStartedAt?: Maybe<Scalars['GermanDateOnly']>;
};

export type BillingContact = {
  __typename?: 'BillingContact';
  city?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  mobile_telephone?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  postalcode?: Maybe<Scalars['String']>;
  salutation?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  telephone?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type BillingContactI = {
  city?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  mobile_telephone?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  postalcode?: InputMaybe<Scalars['String']>;
  salutation?: InputMaybe<Scalars['String']>;
  street?: InputMaybe<Scalars['String']>;
  telephone?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type BillingI = {
  address?: InputMaybe<BillingContactI>;
  bank?: InputMaybe<BankDetailsI>;
};

export type Block = IBlock & {
  __typename?: 'Block';
  _id: Scalars['String'];
  ancestorFolders?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt: Scalars['DateTime'];
  invoices?: Maybe<Array<Maybe<Invoice>>>;
  isDirectory: Scalars['Boolean'];
  name: Scalars['String'];
  parentFolder?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Int']>;
  updatedAt: Scalars['DateTime'];
};

export type ChangePatientPasswordInput = {
  currentPassword: Scalars['String'];
  newPassword: Scalars['String'];
};

export type Client = {
  __typename?: 'Client';
  _id?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  client_type?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  newpasswordrequired?: Maybe<Scalars['Boolean']>;
  node?: Maybe<Node>;
  reminder_settings?: Maybe<ReminderSettings>;
  roles?: Maybe<Scalars['JSON']>;
  salutation?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type CompanyNotification = ICompanyNotification & {
  __typename?: 'CompanyNotification';
  _id: Scalars['String'];
  content: Scalars['String'];
  createdAt: Scalars['DateTime'];
  publishAt?: Maybe<Scalars['DateTime']>;
  published?: Maybe<Scalars['Boolean']>;
  showReloadAction?: Maybe<Scalars['Boolean']>;
  title: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type CompanyNotificationCreateInput = {
  content: Scalars['String'];
  publishAt?: InputMaybe<Scalars['DateTime']>;
  showReloadAction?: InputMaybe<Scalars['Boolean']>;
  title: Scalars['String'];
};

export type CompanyNotificationUpdateInput = {
  _id: Scalars['String'];
  content: Scalars['String'];
  publishAt?: InputMaybe<Scalars['DateTime']>;
  showReloadAction?: InputMaybe<Scalars['Boolean']>;
  title: Scalars['String'];
};

export type Contact = {
  __typename?: 'Contact';
  _id?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  phonenumber?: Maybe<Scalars['String']>;
  salutation?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type Document = InvoiceDocument | ReminderDocument;

export type Epost = {
  __typename?: 'Epost';
  active?: Maybe<Scalars['Boolean']>;
  ekp?: Maybe<Scalars['String']>;
  vendor_id?: Maybe<Scalars['String']>;
};

export type EpostI = {
  active?: InputMaybe<Scalars['Boolean']>;
  ekp?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
  secret?: InputMaybe<Scalars['String']>;
  vendor_id?: InputMaybe<Scalars['String']>;
};

export type Error = {
  __typename?: 'Error';
  message?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type ErrorLog = {
  __typename?: 'ErrorLog';
  _id: Scalars['String'];
  date?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  error?: Maybe<Error>;
  node?: Maybe<Node>;
  originator?: Maybe<Scalars['String']>;
  process?: Maybe<Scalars['String']>;
  subject?: Maybe<Scalars['String']>;
};

export type FieldHistory = {
  __typename?: 'FieldHistory';
  at?: Maybe<Scalars['String']>;
  by?: Maybe<Scalars['JSON']>;
  from?: Maybe<Scalars['String']>;
  to?: Maybe<Scalars['String']>;
};

export type File = {
  __typename?: 'File';
  _id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['GermanDate']>;
  dueAt?: Maybe<Scalars['GermanDateOnly']>;
  filename?: Maybe<Scalars['String']>;
  invoices?: Maybe<Array<Maybe<Invoice>>>;
  node?: Maybe<Node>;
  record000?: Maybe<Record000>;
  record990?: Maybe<Record990>;
  sentAt?: Maybe<Scalars['GermanDate']>;
  state?: Maybe<Scalars['String']>;
  statistics?: Maybe<Statistics>;
  total?: Maybe<Scalars['Float']>;
};


export type FileInvoicesArgs = {
  processState?: InputMaybe<Array<InvoiceProcessState>>;
};

export type FileCreateSummary = {
  __typename?: 'FileCreateSummary';
  _id?: Maybe<Scalars['String']>;
  createdCount?: Maybe<Scalars['Int']>;
  filename?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
};

export type FileError = {
  __typename?: 'FileError';
  errorCode?: Maybe<Scalars['String']>;
  file?: Maybe<File>;
};

export type FinApi = {
  __typename?: 'FinApi';
  accountid?: Maybe<Scalars['String']>;
  bankconnectionid?: Maybe<Scalars['String']>;
  errorcode?: Maybe<Scalars['String']>;
  errormessage?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  userid?: Maybe<Scalars['String']>;
  userpassword?: Maybe<Scalars['String']>;
};

export type FinApiI = {
  accountid?: InputMaybe<Scalars['String']>;
  bankconnectionid?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  userid?: InputMaybe<Scalars['String']>;
  userpassword?: InputMaybe<Scalars['String']>;
};

export type ForgotPasswordInput = {
  email: Scalars['String'];
};

export type History = {
  __typename?: 'History';
  amount?: Maybe<Scalars['Float']>;
  bookingdate?: Maybe<Scalars['GermanDateOnly']>;
  id: Scalars['String'];
  note?: Maybe<Scalars['String']>;
  reminderLevel?: Maybe<Scalars['Int']>;
  state?: Maybe<Scalars['String']>;
  type: Scalars['String'];
};

export type IBlock = {
  createdAt: Scalars['DateTime'];
  isDirectory: Scalars['Boolean'];
  name: Scalars['String'];
  size?: Maybe<Scalars['Int']>;
  updatedAt: Scalars['DateTime'];
};

export type ICompanyNotification = {
  _id: Scalars['String'];
  content: Scalars['String'];
  createdAt: Scalars['DateTime'];
  publishAt?: Maybe<Scalars['DateTime']>;
  published?: Maybe<Scalars['Boolean']>;
  showReloadAction?: Maybe<Scalars['Boolean']>;
  title: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type IContact = {
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  phonenumber?: InputMaybe<Scalars['String']>;
  salutation?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type INodeInfo = {
  city?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  phonenumber?: InputMaybe<Scalars['String']>;
  postalcode?: InputMaybe<Scalars['String']>;
  street?: InputMaybe<Scalars['String']>;
};

export type IPatient = {
  birthday?: Maybe<Scalars['GermanDateOnly']>;
  city?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  email: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  preferredDeliveryMethod: PreferredDeliveryMethod;
  street?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  validated: Scalars['Boolean'];
};

export type IProspect = {
  _id?: InputMaybe<Scalars['String']>;
  comment?: InputMaybe<Scalars['String']>;
};

export type IReminderSettings = {
  unclear_transactions_reminder?: InputMaybe<Scalars['Boolean']>;
};

export type InputRecord300 = {
  AbzugKZ?: InputMaybe<Scalars['String']>;
  Aufnahmedatum?: InputMaybe<Scalars['String']>;
  Behandelter?: InputMaybe<Scalars['String']>;
  DruckKennzeichen?: InputMaybe<Scalars['String']>;
  Entlassungsdatum?: InputMaybe<Scalars['String']>;
  Geburtsdatum?: InputMaybe<Scalars['String']>;
  Geschlechtkennung?: InputMaybe<Scalars['String']>;
  Klasse?: InputMaybe<Scalars['Int']>;
  Land_Plz_Ort?: InputMaybe<Scalars['String']>;
  MitgliedsNr?: InputMaybe<Scalars['Int']>;
  MwSt?: InputMaybe<Scalars['Float']>;
  Ostabschlag?: InputMaybe<Scalars['String']>;
  Prozentsatz?: InputMaybe<Scalars['Int']>;
  RechnungsNr?: InputMaybe<Scalars['String']>;
  Rechnungstyp?: InputMaybe<Scalars['String']>;
  Satzart?: InputMaybe<Scalars['String']>;
  Tage?: InputMaybe<Scalars['Int']>;
  TextKZ?: InputMaybe<Scalars['Int']>;
  Waehrung?: InputMaybe<Scalars['String']>;
  Zahlungsart?: InputMaybe<Scalars['String']>;
  leer?: InputMaybe<Scalars['String']>;
};

export type InputRecord700 = {
  Allgemeine_Kosten?: InputMaybe<Scalars['Float']>;
  Anzahl?: InputMaybe<Scalars['Int']>;
  Besondere_Kosten?: InputMaybe<Scalars['Float']>;
  Betrag?: InputMaybe<Scalars['Float']>;
  Datum?: InputMaybe<Scalars['String']>;
  Einfachwert?: InputMaybe<Scalars['Float']>;
  Faktor?: InputMaybe<Scalars['Float']>;
  Leistungsziffer?: InputMaybe<Scalars['String']>;
  MitgliedsNr?: InputMaybe<Scalars['Int']>;
  MwSt?: InputMaybe<Scalars['Float']>;
  PositionsKZ?: InputMaybe<Scalars['String']>;
  RechnungsNr?: InputMaybe<Scalars['String']>;
  Satzart?: InputMaybe<Scalars['String']>;
  Text?: InputMaybe<Scalars['String']>;
  Uhrzeit?: InputMaybe<Scalars['String']>;
  ZahnAngabe?: InputMaybe<Scalars['String']>;
  leer?: InputMaybe<Scalars['String']>;
};

export type Invoice = {
  __typename?: 'Invoice';
  _id: Scalars['String'];
  address?: Maybe<InvoiceAddress>;
  amount?: Maybe<Scalars['Float']>;
  attachments?: Maybe<Array<Maybe<Block>>>;
  balance?: Maybe<Scalars['String']>;
  billedAt?: Maybe<Scalars['GermanDateOnly']>;
  deliveryMethod?: Maybe<Scalars['String']>;
  dueAt?: Maybe<Scalars['GermanDateOnly']>;
  file: File;
  financial_state?: Maybe<Scalars['String']>;
  firstname?: Maybe<Scalars['String']>;
  girocode?: Maybe<Scalars['String']>;
  history?: Maybe<Array<Maybe<History>>>;
  invoicenr?: Maybe<Scalars['String']>;
  lastname?: Maybe<Scalars['String']>;
  metadata?: Maybe<MetaData>;
  node: Node;
  patient?: Maybe<Scalars['String']>;
  patientfirstname?: Maybe<Scalars['String']>;
  patientlastname?: Maybe<Scalars['String']>;
  processState?: Maybe<InvoiceProcessState>;
  recipient?: Maybe<Scalars['String']>;
  record100?: Maybe<Array<Maybe<Record100>>>;
  record200?: Maybe<Record200>;
  record300?: Maybe<Record300>;
  record310?: Maybe<Record310>;
  record320?: Maybe<Record320>;
  record400?: Maybe<Array<Maybe<Record400>>>;
  record500?: Maybe<Array<Maybe<Record500>>>;
  record600?: Maybe<Array<Maybe<Record600>>>;
  record700?: Maybe<Array<Maybe<Record700>>>;
  record700s?: Maybe<Array<Maybe<Record700s>>>;
  record800?: Maybe<Array<Maybe<Record800>>>;
  record900?: Maybe<Record900>;
  reminderLevel?: Maybe<Scalars['Int']>;
  seenAt?: Maybe<Scalars['GermanDateOnly']>;
  state?: Maybe<Scalars['String']>;
  statistics?: Maybe<Statistics>;
  transactions?: Maybe<Array<Maybe<Transaction>>>;
};

export type InvoiceAddress = {
  __typename?: 'InvoiceAddress';
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  recipient?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
};

export type InvoiceAddressInput = {
  city: Scalars['String'];
  country: Scalars['String'];
  street: Scalars['String'];
  zipCode: Scalars['String'];
};

export type InvoiceDataInput = {
  address?: InputMaybe<InvoiceAddressInput>;
  attachments?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  patient?: InputMaybe<Scalars['String']>;
  recipient?: InputMaybe<Scalars['String']>;
  record300?: InputMaybe<InputRecord300>;
  record700?: InputMaybe<Array<InputMaybe<InputRecord700>>>;
};

export type InvoiceDocument = {
  __typename?: 'InvoiceDocument';
  _id: Scalars['String'];
  billetAt: Scalars['GermanDateOnly'];
  downloadUrl: Scalars['String'];
  dueAt: Scalars['GermanDateOnly'];
  financialState: Scalars['String'];
  node: Node;
};

export enum InvoiceProcessState {
  Exclude = 'EXCLUDE',
  Invalid = 'INVALID',
  Valid = 'VALID'
}

export type InvoiceSenderInformation = {
  __typename?: 'InvoiceSenderInformation';
  header_one?: Maybe<Scalars['String']>;
  header_two?: Maybe<Scalars['String']>;
  letter_window_one?: Maybe<Scalars['String']>;
  letter_window_two?: Maybe<Scalars['String']>;
  sender_address_eight?: Maybe<Scalars['String']>;
  sender_address_five?: Maybe<Scalars['String']>;
  sender_address_four?: Maybe<Scalars['String']>;
  sender_address_nine?: Maybe<Scalars['String']>;
  sender_address_one?: Maybe<Scalars['String']>;
  sender_address_seven?: Maybe<Scalars['String']>;
  sender_address_six?: Maybe<Scalars['String']>;
  sender_address_three?: Maybe<Scalars['String']>;
  sender_address_two?: Maybe<Scalars['String']>;
};

export type InvoiceSenderInformationI = {
  _id?: InputMaybe<Scalars['String']>;
  header_one?: InputMaybe<Scalars['String']>;
  header_two?: InputMaybe<Scalars['String']>;
  letter_window_one?: InputMaybe<Scalars['String']>;
  letter_window_two?: InputMaybe<Scalars['String']>;
  sender_address_eight?: InputMaybe<Scalars['String']>;
  sender_address_five?: InputMaybe<Scalars['String']>;
  sender_address_four?: InputMaybe<Scalars['String']>;
  sender_address_nine?: InputMaybe<Scalars['String']>;
  sender_address_one?: InputMaybe<Scalars['String']>;
  sender_address_seven?: InputMaybe<Scalars['String']>;
  sender_address_six?: InputMaybe<Scalars['String']>;
  sender_address_three?: InputMaybe<Scalars['String']>;
  sender_address_two?: InputMaybe<Scalars['String']>;
};

export type InvoicesSummary = {
  __typename?: 'InvoicesSummary';
  balance?: Maybe<Scalars['Float']>;
  sum: Scalars['Float'];
  total: Scalars['Int'];
};

export type LinkPatientInput = {
  assignmentCode: Scalars['String'];
  verification: Scalars['String'];
};

export type LoginPatientInput = {
  email: Scalars['String'];
  password: Scalars['String'];
};

export type MarkInvoiceAsSeenInput = {
  invoiceId: Scalars['ID'];
};

export type MetaData = {
  __typename?: 'MetaData';
  iban: Scalars['String'];
  sender_address_one: Scalars['String'];
  sender_address_three: Scalars['String'];
  sender_address_two: Scalars['String'];
};

export type MonthStatistics = {
  __typename?: 'MonthStatistics';
  label: Scalars['String'];
  month: Scalars['String'];
  numberOfEntries: Scalars['Int'];
  statistics: Statistics;
};

export type Mutation = {
  __typename?: 'Mutation';
  addCustomTransaction?: Maybe<Invoice>;
  admin_add_company_notification?: Maybe<CompanyNotification>;
  admin_comment_prospect?: Maybe<Scalars['Boolean']>;
  admin_company_notitifications?: Maybe<Array<Maybe<CompanyNotification>>>;
  admin_delete_company_notification?: Maybe<CompanyNotification>;
  admin_edit_company_notification?: Maybe<CompanyNotification>;
  admin_login?: Maybe<Client>;
  admin_logout?: Maybe<Scalars['Boolean']>;
  admin_publish_company_notification?: Maybe<CompanyNotification>;
  admin_reset_client_password?: Maybe<Scalars['String']>;
  assignTransactionToInvoice?: Maybe<AssignmentResult>;
  changePatientEmail: Scalars['Boolean'];
  changePatientPassword: Patient;
  confirmProspect?: Maybe<Scalars['Boolean']>;
  createClientFromProspect?: Maybe<Scalars['Boolean']>;
  createDirectory?: Maybe<Block>;
  createInvoicesFromFile: FileCreateSummary;
  createProspect?: Maybe<Scalars['Boolean']>;
  deleteDirectory?: Maybe<Block>;
  deleteDocument?: Maybe<Block>;
  deleteInvoice?: Maybe<Invoice>;
  excludeInvoiceFromProcessing?: Maybe<Invoice>;
  /**
   * Triggers forgotPassword workflow
   * Only sends mail if email is present, always returns true
   */
  forgotPassword: Scalars['Boolean'];
  includeInvoiceInProcessing?: Maybe<Invoice>;
  linkPatient: Scalars['Boolean'];
  loginPatient: TokenPayload;
  logoutPatient?: Maybe<Scalars['Boolean']>;
  markCompanyNotificationAsSeen?: Maybe<CompanyNotification>;
  markInvoiceAsSeen?: Maybe<Invoice>;
  markNotificationsAsRead?: Maybe<Array<Maybe<Notification>>>;
  markTransactionAsNotRelevant?: Maybe<Transaction>;
  markTransactionAsRelevant?: Maybe<Transaction>;
  moveDirectory?: Maybe<Block>;
  moveDocument?: Maybe<Block>;
  refreshToken: TokenPayload;
  registerPatient: Patient;
  releaseReminder?: Maybe<Invoice>;
  removeCustomTransaction?: Maybe<Invoice>;
  removeFile?: Maybe<UpdateResult>;
  renameBlock?: Maybe<Block>;
  setContactProspect?: Maybe<Scalars['Boolean']>;
  setPasswordProspect?: Maybe<Scalars['Boolean']>;
  unassignTransactionFromInvoice?: Maybe<AssignmentResult>;
  undoWaiveRemainingAmount?: Maybe<Invoice>;
  updateClient?: Maybe<UpdateResult>;
  /**
   * update forgotten password
   * possible errors:
   * - auth.unauthorized
   * - patient.errors.weak.password
   * - patient.errors.forgotpassword.invalidtoken
   * - patient.errors.forgotpassword.usernotfound
   */
  updateForgottenPassword: Scalars['Boolean'];
  updateInvoice?: Maybe<Invoice>;
  updateNode: Node;
  updatePatient: Patient;
  updatePatientEmail: Patient;
  updatePatientPassword: Patient;
  updatePreferredDeliveryMethod: Patient;
  updateReminders?: Maybe<UpdateResult>;
  uploadDocument?: Maybe<Block>;
  uploadFile: UploadSummary;
  validateIndividual: Scalars['Boolean'];
  verifyPatient: TokenPayload;
  waiveRemainingAmount?: Maybe<Invoice>;
  writeBackInvoice?: Maybe<Invoice>;
  writeOffInvoice?: Maybe<Invoice>;
};


export type MutationAddCustomTransactionArgs = {
  amount: Scalars['Float'];
  bookingdate: Scalars['String'];
  invoiceid: Scalars['String'];
  note?: InputMaybe<Scalars['String']>;
};


export type MutationAdmin_Add_Company_NotificationArgs = {
  companyNotification: CompanyNotificationCreateInput;
};


export type MutationAdmin_Comment_ProspectArgs = {
  prospect?: InputMaybe<IProspect>;
};


export type MutationAdmin_Delete_Company_NotificationArgs = {
  companyNotificationId: Scalars['String'];
};


export type MutationAdmin_Edit_Company_NotificationArgs = {
  companyNotification: CompanyNotificationUpdateInput;
};


export type MutationAdmin_LoginArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};


export type MutationAdmin_Publish_Company_NotificationArgs = {
  companyNotificationId: Scalars['String'];
};


export type MutationAdmin_Reset_Client_PasswordArgs = {
  id: Scalars['String'];
};


export type MutationAssignTransactionToInvoiceArgs = {
  invoiceid: Scalars['String'];
  transactionid: Scalars['String'];
};


export type MutationChangePatientEmailArgs = {
  input: PatientEmailChangeInput;
};


export type MutationChangePatientPasswordArgs = {
  input: ChangePatientPasswordInput;
};


export type MutationConfirmProspectArgs = {
  token: Scalars['String'];
};


export type MutationCreateClientFromProspectArgs = {
  nodeInfo: INodeInfo;
  token: Scalars['String'];
};


export type MutationCreateDirectoryArgs = {
  name: Scalars['String'];
  parentBlockId?: InputMaybe<Scalars['String']>;
};


export type MutationCreateInvoicesFromFileArgs = {
  id: Scalars['String'];
};


export type MutationCreateProspectArgs = {
  email: Scalars['String'];
  partnerId?: InputMaybe<Scalars['String']>;
};


export type MutationDeleteDirectoryArgs = {
  blockId: Scalars['String'];
};


export type MutationDeleteDocumentArgs = {
  blockId: Scalars['String'];
};


export type MutationDeleteInvoiceArgs = {
  invoiceId: Scalars['String'];
};


export type MutationExcludeInvoiceFromProcessingArgs = {
  invoiceid: Scalars['String'];
};


export type MutationForgotPasswordArgs = {
  input: ForgotPasswordInput;
};


export type MutationIncludeInvoiceInProcessingArgs = {
  invoiceid: Scalars['String'];
};


export type MutationLinkPatientArgs = {
  input: LinkPatientInput;
};


export type MutationLoginPatientArgs = {
  input: LoginPatientInput;
};


export type MutationMarkCompanyNotificationAsSeenArgs = {
  companyNotificationId: Scalars['String'];
};


export type MutationMarkInvoiceAsSeenArgs = {
  input: MarkInvoiceAsSeenInput;
};


export type MutationMarkNotificationsAsReadArgs = {
  type: Scalars['String'];
};


export type MutationMarkTransactionAsNotRelevantArgs = {
  transactionid: Scalars['String'];
};


export type MutationMarkTransactionAsRelevantArgs = {
  transactionid: Scalars['String'];
};


export type MutationMoveDirectoryArgs = {
  blockId: Scalars['String'];
  destinationParentBlockId?: InputMaybe<Scalars['String']>;
};


export type MutationMoveDocumentArgs = {
  blockId: Scalars['String'];
  destinationParentBlockId?: InputMaybe<Scalars['String']>;
};


export type MutationRefreshTokenArgs = {
  type: TokenType;
};


export type MutationRegisterPatientArgs = {
  input: PatientRegisterInput;
};


export type MutationReleaseReminderArgs = {
  invoiceid: Scalars['String'];
};


export type MutationRemoveCustomTransactionArgs = {
  invoiceid: Scalars['String'];
  transactionid: Scalars['String'];
};


export type MutationRemoveFileArgs = {
  fileid: Scalars['String'];
  password: Scalars['String'];
};


export type MutationRenameBlockArgs = {
  blockId: Scalars['String'];
  name: Scalars['String'];
};


export type MutationSetContactProspectArgs = {
  contact: IContact;
  token: Scalars['String'];
};


export type MutationSetPasswordProspectArgs = {
  password: Scalars['String'];
  token: Scalars['String'];
};


export type MutationUnassignTransactionFromInvoiceArgs = {
  invoiceid: Scalars['String'];
  transactionid: Scalars['String'];
};


export type MutationUndoWaiveRemainingAmountArgs = {
  invoiceid: Scalars['String'];
  transactionid: Scalars['String'];
};


export type MutationUpdateClientArgs = {
  client: UpdateClient;
};


export type MutationUpdateForgottenPasswordArgs = {
  input: UpdateForgottenPasswordInput;
};


export type MutationUpdateInvoiceArgs = {
  invoiceData: InvoiceDataInput;
  invoiceId: Scalars['String'];
};


export type MutationUpdateNodeArgs = {
  mac_address?: InputMaybe<Scalars['String']>;
  node?: InputMaybe<NodeI>;
  startContract?: InputMaybe<Scalars['Boolean']>;
};


export type MutationUpdatePatientArgs = {
  input: PatientUpdateInput;
};


export type MutationUpdatePatientEmailArgs = {
  input: PatientEmailUpdateInput;
};


export type MutationUpdatePatientPasswordArgs = {
  input: PatientPasswordInput;
};


export type MutationUpdatePreferredDeliveryMethodArgs = {
  input: PreferredDeliveryMethodInput;
};


export type MutationUpdateRemindersArgs = {
  active: Scalars['Boolean'];
  reminders: Array<InputMaybe<ReminderInput>>;
};


export type MutationUploadDocumentArgs = {
  document: Scalars['Upload'];
  parentBlockId?: InputMaybe<Scalars['String']>;
};


export type MutationUploadFileArgs = {
  file: Scalars['Upload'];
};


export type MutationValidateIndividualArgs = {
  input: ValidateIndividualInput;
};


export type MutationVerifyPatientArgs = {
  input: VerifyPatientInput;
};


export type MutationWaiveRemainingAmountArgs = {
  invoiceid: Scalars['String'];
};


export type MutationWriteBackInvoiceArgs = {
  invoiceid: Scalars['String'];
};


export type MutationWriteOffInvoiceArgs = {
  invoiceid: Scalars['String'];
};

export type Node = {
  __typename?: 'Node';
  _id: Scalars['String'];
  bank_details?: Maybe<BankDetails>;
  billing?: Maybe<Billing>;
  children?: Maybe<Array<Maybe<Node>>>;
  city?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['Int']>;
  dueDays: Scalars['Int'];
  email?: Maybe<Scalars['String']>;
  epost?: Maybe<Epost>;
  faxnumber?: Maybe<Scalars['String']>;
  files?: Maybe<Array<Maybe<File>>>;
  finapi?: Maybe<FinApi>;
  headerlogolines?: Maybe<Array<Maybe<Scalars['String']>>>;
  invoice_sender_information?: Maybe<InvoiceSenderInformation>;
  invoices?: Maybe<Array<Maybe<Invoice>>>;
  mobilenumber?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  pad_folder?: Maybe<Array<Maybe<PadFolder>>>;
  parent?: Maybe<Node>;
  phonenumber?: Maybe<Scalars['String']>;
  postalcode?: Maybe<Scalars['String']>;
  reminder_details?: Maybe<ReminderDetails>;
  state?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  taxnumber?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  ustid?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
};

export type NodeI = {
  _id?: InputMaybe<Scalars['String']>;
  bank_details?: InputMaybe<BankDetailsI>;
  billing?: InputMaybe<BillingI>;
  city?: InputMaybe<Scalars['String']>;
  customerID?: InputMaybe<Scalars['Int']>;
  dueDays: Scalars['Int'];
  email?: InputMaybe<Scalars['String']>;
  epost?: InputMaybe<EpostI>;
  faxnumber?: InputMaybe<Scalars['String']>;
  finapi?: InputMaybe<FinApiI>;
  headerlogolines?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  invoice_sender_information?: InputMaybe<InvoiceSenderInformationI>;
  mobilenumber?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  pad_folder?: InputMaybe<Array<InputMaybe<PadFolderI>>>;
  phonenumber?: InputMaybe<Scalars['String']>;
  postalcode?: InputMaybe<Scalars['String']>;
  reminder_details?: InputMaybe<ReminderInput>;
  state?: InputMaybe<Scalars['String']>;
  street?: InputMaybe<Scalars['String']>;
  taxnumber?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  ustid?: InputMaybe<Scalars['String']>;
  website?: InputMaybe<Scalars['String']>;
};

export type NodeInfo = {
  __typename?: 'NodeInfo';
  _id?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  phonenumber?: Maybe<Scalars['String']>;
  postalcode?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
};

export type Notification = {
  __typename?: 'Notification';
  _concerning_object?: Maybe<Scalars['String']>;
  _id?: Maybe<Scalars['String']>;
  _node?: Maybe<Node>;
  createdAt?: Maybe<Scalars['GermanDateOnly']>;
  notification_type?: Maybe<Scalars['String']>;
};

export type PadFolder = {
  __typename?: 'PadFolder';
  folder?: Maybe<Scalars['String']>;
  mac?: Maybe<Scalars['String']>;
  ready?: Maybe<Scalars['Boolean']>;
};

export type PadFolderI = {
  folder?: InputMaybe<Scalars['String']>;
  mac?: InputMaybe<Scalars['String']>;
  ready?: InputMaybe<Scalars['Boolean']>;
};

export type Patient = IPatient & {
  __typename?: 'Patient';
  _id: Scalars['String'];
  birthday?: Maybe<Scalars['GermanDateOnly']>;
  city?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  email: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  preferredDeliveryMethod: PreferredDeliveryMethod;
  street?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  validated: Scalars['Boolean'];
};

export type PatientEmailChangeInput = {
  email: Scalars['String'];
};

export type PatientEmailUpdateInput = {
  email: Scalars['String'];
  token: Scalars['String'];
};

export type PatientPasswordInput = {
  password: Scalars['String'];
};

export type PatientRegisterInput = {
  email: Scalars['String'];
};

export type PatientUpdateInput = {
  birthday?: InputMaybe<Scalars['GermanDateOnly']>;
  city?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  postalCode?: InputMaybe<Scalars['String']>;
  street?: InputMaybe<Scalars['String']>;
};

export enum PreferredDeliveryMethod {
  Digital = 'Digital',
  Epost = 'Epost'
}

export type PreferredDeliveryMethodInput = {
  preferredDeliveryMethod: PreferredDeliveryMethod;
};

export type Prospect = {
  __typename?: 'Prospect';
  _id?: Maybe<Scalars['String']>;
  clientState?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  contact?: Maybe<Contact>;
  createdAt?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  email_token?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  nodeInfo?: Maybe<NodeInfo>;
  partnerId?: Maybe<Scalars['String']>;
  referrer?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  stateHistory?: Maybe<Array<Maybe<FieldHistory>>>;
};

export type Query = {
  __typename?: 'Query';
  admin_clients?: Maybe<Array<Maybe<Client>>>;
  admin_company_notifications: Array<Maybe<CompanyNotification>>;
  admin_errorlogs?: Maybe<Array<Maybe<ErrorLog>>>;
  admin_files?: Maybe<Array<Maybe<File>>>;
  admin_invoices?: Maybe<Array<Maybe<Invoice>>>;
  admin_node?: Maybe<Node>;
  admin_node_errorlogs?: Maybe<Array<Maybe<ErrorLog>>>;
  admin_node_raw?: Maybe<Scalars['JSON']>;
  admin_node_tree?: Maybe<Tree>;
  admin_nodes?: Maybe<Array<Maybe<Node>>>;
  admin_patients: Array<Maybe<IPatient>>;
  admin_prospects?: Maybe<Array<Maybe<Prospect>>>;
  assignmentsAndProposals?: Maybe<Array<Maybe<Invoice>>>;
  availableCountries?: Maybe<Array<Maybe<Scalars['String']>>>;
  blocks?: Maybe<Array<Maybe<Block>>>;
  client?: Maybe<Client>;
  companyNotification?: Maybe<CompanyNotification>;
  currentClient?: Maybe<Client>;
  demoDataNodes?: Maybe<Array<Maybe<Node>>>;
  errorlogs?: Maybe<Array<Maybe<ErrorLog>>>;
  invoice?: Maybe<Invoice>;
  month_statistics?: Maybe<Array<Maybe<MonthStatistics>>>;
  node?: Maybe<Node>;
  nodeInvoicesSummary?: Maybe<InvoicesSummary>;
  node_files?: Maybe<Array<Maybe<File>>>;
  node_invoices?: Maybe<Array<Maybe<Invoice>>>;
  notifications?: Maybe<Array<Maybe<Notification>>>;
  openpayments?: Maybe<Statistics>;
  patient: Patient;
  patientDocuments?: Maybe<Array<Maybe<Document>>>;
  patientInvoiceAsPdf?: Maybe<Scalars['GraphQLBuffer']>;
  patientInvoices: Array<Maybe<Invoice>>;
  pdfsFromFile?: Maybe<ZipFile>;
  service?: Maybe<Service>;
  services?: Maybe<Array<Maybe<Service>>>;
  transaction?: Maybe<Transaction>;
  transactions?: Maybe<Array<Maybe<Transaction>>>;
  trial_conditions?: Maybe<TrialConditions>;
  validateZipCode?: Maybe<Scalars['Boolean']>;
};


export type QueryAdmin_ClientsArgs = {
  node?: InputMaybe<Scalars['String']>;
};


export type QueryAdmin_InvoicesArgs = {
  filter?: InputMaybe<Scalars['String']>;
  financial_states?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  from?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  node?: InputMaybe<Scalars['String']>;
  to?: InputMaybe<Scalars['String']>;
};


export type QueryAdmin_NodeArgs = {
  id?: InputMaybe<Scalars['String']>;
};


export type QueryAdmin_Node_ErrorlogsArgs = {
  node?: InputMaybe<Scalars['String']>;
};


export type QueryAdmin_Node_RawArgs = {
  id?: InputMaybe<Scalars['String']>;
};


export type QueryAdmin_Node_TreeArgs = {
  id?: InputMaybe<Scalars['String']>;
};


export type QueryAdmin_ProspectsArgs = {
  sortBy?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
};


export type QueryAssignmentsAndProposalsArgs = {
  maxnumber: Scalars['Int'];
  minpoints: Scalars['Int'];
  transactionid: Scalars['String'];
};


export type QueryInvoiceArgs = {
  id: Scalars['String'];
};


export type QueryMonth_StatisticsArgs = {
  from?: InputMaybe<Scalars['String']>;
  to?: InputMaybe<Scalars['String']>;
};


export type QueryNodeInvoicesSummaryArgs = {
  from?: InputMaybe<Scalars['String']>;
  searchTerm?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  to?: InputMaybe<Scalars['String']>;
};


export type QueryNode_FilesArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  state?: InputMaybe<Scalars['String']>;
};


export type QueryNode_InvoicesArgs = {
  from?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  searchTerm?: InputMaybe<Scalars['String']>;
  skip?: InputMaybe<Scalars['Int']>;
  sortBy?: InputMaybe<Scalars['String']>;
  sortDirection?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  to?: InputMaybe<Scalars['String']>;
};


export type QueryNotificationsArgs = {
  show_all?: InputMaybe<Scalars['Boolean']>;
};


export type QueryPatientInvoiceAsPdfArgs = {
  invoiceId: Scalars['String'];
};


export type QueryPatientInvoicesArgs = {
  limit: Scalars['Int'];
  skip: Scalars['Int'];
};


export type QueryPdfsFromFileArgs = {
  id: Scalars['String'];
};


export type QueryServiceArgs = {
  cipher?: InputMaybe<Scalars['String']>;
};


export type QueryServicesArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  searchTerm?: InputMaybe<Scalars['String']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type QueryTransactionArgs = {
  _id?: InputMaybe<Scalars['String']>;
};


export type QueryTransactionsArgs = {
  contain?: InputMaybe<Scalars['String']>;
  from?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  states?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  to?: InputMaybe<Scalars['String']>;
};


export type QueryTrial_ConditionsArgs = {
  pid?: InputMaybe<Scalars['String']>;
};


export type QueryValidateZipCodeArgs = {
  countryCode: Scalars['String'];
  zipCode: Scalars['String'];
};

export type Record000 = {
  __typename?: 'Record000';
  Dateiname?: Maybe<Scalars['String']>;
  Erstellungsdatum?: Maybe<Scalars['String']>;
  MitgliedsNr?: Maybe<Scalars['Int']>;
  Praxiscomputer?: Maybe<Scalars['String']>;
  RechnungsNr?: Maybe<Scalars['String']>;
  Satzart?: Maybe<Scalars['String']>;
  Uhrzeit?: Maybe<Scalars['String']>;
  Version?: Maybe<Scalars['String']>;
  Volumename?: Maybe<Scalars['String']>;
  Waehrung?: Maybe<Scalars['String']>;
  leer?: Maybe<Scalars['String']>;
};

export type Record100 = {
  __typename?: 'Record100';
  MitgliedsNr?: Maybe<Scalars['Int']>;
  RechnungsNr?: Maybe<Scalars['String']>;
  Satzart?: Maybe<Scalars['String']>;
  Textzeile1?: Maybe<Scalars['String']>;
  Textzeile2?: Maybe<Scalars['String']>;
  leer?: Maybe<Scalars['String']>;
};

export type Record200 = {
  __typename?: 'Record200';
  AnlageKZ?: Maybe<Scalars['String']>;
  AnredeKZ?: Maybe<Scalars['String']>;
  MitgliedsNr?: Maybe<Scalars['Int']>;
  PNR?: Maybe<Scalars['Int']>;
  RechnungsNr?: Maybe<Scalars['String']>;
  Rechnungsempfaenger?: Maybe<Scalars['String']>;
  Satzart?: Maybe<Scalars['String']>;
  Strasse?: Maybe<Scalars['String']>;
  Unterkonto?: Maybe<Scalars['String']>;
  VNR?: Maybe<Scalars['Int']>;
  VUNR?: Maybe<Scalars['Int']>;
  Vertragsart?: Maybe<Scalars['Int']>;
  leer?: Maybe<Scalars['String']>;
};

export type Record300 = {
  __typename?: 'Record300';
  AbzugKZ?: Maybe<Scalars['String']>;
  Aufnahmedatum?: Maybe<Scalars['String']>;
  Behandelter?: Maybe<Scalars['String']>;
  DruckKennzeichen?: Maybe<Scalars['String']>;
  Entlassungsdatum?: Maybe<Scalars['String']>;
  Geburtsdatum?: Maybe<Scalars['String']>;
  Geschlechtkennung?: Maybe<Scalars['String']>;
  Klasse?: Maybe<Scalars['Int']>;
  Land_Plz_Ort?: Maybe<Scalars['String']>;
  MitgliedsNr?: Maybe<Scalars['Int']>;
  MwSt?: Maybe<Scalars['Float']>;
  Ostabschlag?: Maybe<Scalars['String']>;
  Prozentsatz?: Maybe<Scalars['Int']>;
  RechnungsNr?: Maybe<Scalars['String']>;
  Rechnungstyp?: Maybe<Scalars['String']>;
  Satzart?: Maybe<Scalars['String']>;
  Tage?: Maybe<Scalars['Int']>;
  TextKZ?: Maybe<Scalars['Int']>;
  Waehrung?: Maybe<Scalars['String']>;
  Zahlungsart?: Maybe<Scalars['String']>;
  leer?: Maybe<Scalars['String']>;
};

export type Record310 = {
  __typename?: 'Record310';
  Beteiligungsart?: Maybe<Scalars['Int']>;
  Beteiligungswert?: Maybe<Scalars['Float']>;
  MitgliedsNr?: Maybe<Scalars['Int']>;
  RechnungsNr?: Maybe<Scalars['String']>;
  Satzart?: Maybe<Scalars['String']>;
  Unterarzt?: Maybe<Scalars['Int']>;
  leer1?: Maybe<Scalars['String']>;
  leer2?: Maybe<Scalars['String']>;
};

export type Record320 = {
  __typename?: 'Record320';
  AbweichenderKontoinhaber?: Maybe<Scalars['String']>;
  Aktenzeichen?: Maybe<Scalars['String']>;
  Bankleitzahl?: Maybe<Scalars['Int']>;
  Kontonummer?: Maybe<Scalars['Int']>;
  MitgliedsNr?: Maybe<Scalars['Int']>;
  RatenArt?: Maybe<Scalars['String']>;
  RatenWert?: Maybe<Scalars['Int']>;
  Ratendatum?: Maybe<Scalars['String']>;
  Ratenvereinbarung?: Maybe<Scalars['String']>;
  RechnungsNr?: Maybe<Scalars['String']>;
  Satzart?: Maybe<Scalars['String']>;
  leer?: Maybe<Scalars['String']>;
};

export type Record400 = {
  __typename?: 'Record400';
  MitgliedsNr?: Maybe<Scalars['Int']>;
  RechnungsNr?: Maybe<Scalars['String']>;
  Satzart?: Maybe<Scalars['String']>;
  Textzeile1?: Maybe<Scalars['String']>;
  Textzeile2?: Maybe<Scalars['String']>;
  leer?: Maybe<Scalars['String']>;
};

export type Record500 = {
  __typename?: 'Record500';
  MitgliedsNr?: Maybe<Scalars['Int']>;
  RechnungsNr?: Maybe<Scalars['String']>;
  Satzart?: Maybe<Scalars['String']>;
  Textzeile1?: Maybe<Scalars['String']>;
  Textzeile2?: Maybe<Scalars['String']>;
  leer?: Maybe<Scalars['String']>;
};

export type Record600 = {
  __typename?: 'Record600';
  MitgliedsNr?: Maybe<Scalars['Int']>;
  RechnungsNr?: Maybe<Scalars['String']>;
  Satzart?: Maybe<Scalars['String']>;
  Textzeile1?: Maybe<Scalars['String']>;
  Textzeile2?: Maybe<Scalars['String']>;
  leer?: Maybe<Scalars['String']>;
};

export type Record700 = {
  __typename?: 'Record700';
  Allgemeine_Kosten?: Maybe<Scalars['Float']>;
  Anzahl?: Maybe<Scalars['Int']>;
  Besondere_Kosten?: Maybe<Scalars['Float']>;
  Betrag?: Maybe<Scalars['Float']>;
  Datum?: Maybe<Scalars['String']>;
  Einfachwert?: Maybe<Scalars['Float']>;
  Faktor?: Maybe<Scalars['Float']>;
  Leistungsziffer?: Maybe<Scalars['String']>;
  MitgliedsNr?: Maybe<Scalars['Int']>;
  MwSt?: Maybe<Scalars['Float']>;
  PositionsKZ?: Maybe<Scalars['String']>;
  RechnungsNr?: Maybe<Scalars['String']>;
  Satzart?: Maybe<Scalars['String']>;
  Text?: Maybe<Scalars['String']>;
  Uhrzeit?: Maybe<Scalars['String']>;
  ZahnAngabe?: Maybe<Scalars['String']>;
  leer?: Maybe<Scalars['String']>;
};

export type Record700s = {
  __typename?: 'Record700s';
  Anzahl?: Maybe<Scalars['Int']>;
  Betrag?: Maybe<Scalars['Float']>;
  Datum?: Maybe<Scalars['String']>;
  Faktor?: Maybe<Scalars['Float']>;
  Leistungsziffer?: Maybe<Scalars['String']>;
  MitgliedsNr?: Maybe<Scalars['Int']>;
  PositionsKZ?: Maybe<Scalars['String']>;
  RechnungsNr?: Maybe<Scalars['String']>;
  Satzart?: Maybe<Scalars['String']>;
  leer?: Maybe<Scalars['String']>;
};

export type Record800 = {
  __typename?: 'Record800';
  MitgliedsNr?: Maybe<Scalars['Int']>;
  RechnungsNr?: Maybe<Scalars['String']>;
  Satzart?: Maybe<Scalars['String']>;
  Textzeile1?: Maybe<Scalars['String']>;
  Textzeile2?: Maybe<Scalars['String']>;
  leer?: Maybe<Scalars['String']>;
};

export type Record900 = {
  __typename?: 'Record900';
  Anzahl?: Maybe<Scalars['Int']>;
  Anzahlung?: Maybe<Scalars['Float']>;
  MitgliedsNr?: Maybe<Scalars['Int']>;
  Nachlass?: Maybe<Scalars['Float']>;
  RechnungsNr?: Maybe<Scalars['String']>;
  Satzart?: Maybe<Scalars['String']>;
  SummeAllgemeineKosten?: Maybe<Scalars['Float']>;
  SummeBesondereKosten?: Maybe<Scalars['Float']>;
  SummeHonorar?: Maybe<Scalars['Float']>;
  leer?: Maybe<Scalars['String']>;
};

export type Record990 = {
  __typename?: 'Record990';
  AnzahlDatensaetze?: Maybe<Scalars['Int']>;
  AnzahlRechnungen?: Maybe<Scalars['Int']>;
  MitgliedsNr?: Maybe<Scalars['Int']>;
  RechnungsNr?: Maybe<Scalars['String']>;
  Satzart?: Maybe<Scalars['String']>;
  leer?: Maybe<Scalars['String']>;
};

export type Reminder = {
  __typename?: 'Reminder';
  autosend?: Maybe<Scalars['Boolean']>;
  daysAfterPreviousMessage?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  subject?: Maybe<Scalars['String']>;
  threshold?: Maybe<Scalars['Float']>;
};

export type ReminderDetails = {
  __typename?: 'ReminderDetails';
  active?: Maybe<Scalars['Boolean']>;
  reminders?: Maybe<Array<Maybe<Reminder>>>;
};

export type ReminderDocument = {
  __typename?: 'ReminderDocument';
  _id: Scalars['String'];
  downloadUrl: Scalars['String'];
  dueAt: Scalars['GermanDateOnly'];
  node: Node;
  sendAt: Scalars['GermanDateOnly'];
  subject: Scalars['String'];
};

export type ReminderInput = {
  autosend?: InputMaybe<Scalars['Boolean']>;
  subject?: InputMaybe<Scalars['String']>;
  threshold?: InputMaybe<Scalars['Float']>;
};

export type ReminderSettings = {
  __typename?: 'ReminderSettings';
  unclear_transactions_reminder?: Maybe<Scalars['Boolean']>;
};

export type Service = {
  __typename?: 'Service';
  _id: Scalars['Int'];
  cipher: Scalars['String'];
  description: Scalars['String'];
  factors?: Maybe<Array<Maybe<ServiceFactor>>>;
  points: Scalars['Int'];
  rules?: Maybe<Scalars['String']>;
};

export type ServiceFactor = {
  __typename?: 'ServiceFactor';
  amount: Scalars['Float'];
  factor: Scalars['Float'];
};

export type Statistics = {
  __typename?: 'Statistics';
  due: Scalars['Float'];
  open: Scalars['Float'];
  overdue: Scalars['Float'];
  paid: Scalars['Float'];
  total: Scalars['Float'];
  unbilled: Scalars['Float'];
};

export type Subscription = {
  __typename?: 'Subscription';
  fileError?: Maybe<FileError>;
  fileProcessed?: Maybe<File>;
  newCompanyNotification?: Maybe<CompanyNotification>;
  newNotification?: Maybe<Notification>;
};

export type TokenPayload = {
  __typename?: 'TokenPayload';
  accessToken: Scalars['String'];
  accessType?: Maybe<TokenScope>;
};

export enum TokenScope {
  Access = 'ACCESS',
  ForgotPassword = 'FORGOT_PASSWORD',
  Refresh = 'REFRESH'
}

export enum TokenType {
  Patient = 'PATIENT',
  PatientEmailChange = 'PATIENT_EMAIL_CHANGE'
}

export type Transaction = {
  __typename?: 'Transaction';
  _id: Scalars['String'];
  amount?: Maybe<Scalars['String']>;
  bankBookingDate?: Maybe<Scalars['String']>;
  counterpartName?: Maybe<Scalars['String']>;
  details?: Maybe<TransactionDetails>;
  finapiId?: Maybe<Scalars['String']>;
  invoices?: Maybe<Array<Maybe<Invoice>>>;
  node: Node;
  proposals?: Maybe<Array<Maybe<Invoice>>>;
  state?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  unassignedamount?: Maybe<Scalars['String']>;
};

export type TransactionDetails = {
  __typename?: 'TransactionDetails';
  accountId?: Maybe<Scalars['String']>;
  children?: Maybe<Scalars['String']>;
  compensationAmount?: Maybe<Scalars['String']>;
  counterpartAccountNumber?: Maybe<Scalars['String']>;
  counterpartBankName?: Maybe<Scalars['String']>;
  counterpartBic?: Maybe<Scalars['String']>;
  counterpartBlz?: Maybe<Scalars['String']>;
  counterpartCreditorId?: Maybe<Scalars['String']>;
  counterpartCustomerReference?: Maybe<Scalars['String']>;
  counterpartDebitorId?: Maybe<Scalars['String']>;
  counterpartIban?: Maybe<Scalars['String']>;
  counterpartMandateReference?: Maybe<Scalars['String']>;
  counterpartName?: Maybe<Scalars['String']>;
  differentCreditor?: Maybe<Scalars['String']>;
  differentDebitor?: Maybe<Scalars['String']>;
  endToEndReference?: Maybe<Scalars['String']>;
  finapiBookingDate?: Maybe<Scalars['String']>;
  importDate?: Maybe<Scalars['String']>;
  isAdjustingEntry?: Maybe<Scalars['String']>;
  isNew?: Maybe<Scalars['String']>;
  isPotentialDuplicate?: Maybe<Scalars['String']>;
  labels?: Maybe<Scalars['String']>;
  originalAmount?: Maybe<Scalars['String']>;
  parentId?: Maybe<Scalars['String']>;
  paypalData?: Maybe<Scalars['String']>;
  primanota?: Maybe<Scalars['String']>;
  purpose?: Maybe<Scalars['String']>;
  sepaPurposeCode?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  typeCodeSwift?: Maybe<Scalars['String']>;
  typeCodeZka?: Maybe<Scalars['String']>;
  valueDate?: Maybe<Scalars['String']>;
};

export type Tree = {
  __typename?: 'Tree';
  tree?: Maybe<Scalars['JSON']>;
};

export type TrialConditions = {
  __typename?: 'TrialConditions';
  duration?: Maybe<Scalars['Int']>;
  invoices?: Maybe<Scalars['Int']>;
  unit?: Maybe<Scalars['String']>;
};

export type UpdateClient = {
  _id: Scalars['String'];
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  newpassword?: InputMaybe<Scalars['String']>;
  newpasswordconfirmation?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
  reminder_settings?: InputMaybe<IReminderSettings>;
  salutation?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type UpdateForgottenPasswordInput = {
  password: Scalars['String'];
  token: Scalars['String'];
};

export type UpdateResult = {
  __typename?: 'UpdateResult';
  message?: Maybe<Scalars['String']>;
  status: Scalars['String'];
};

export type UploadSummary = {
  __typename?: 'UploadSummary';
  checksum?: Maybe<Scalars['String']>;
  filename: Scalars['String'];
  invoices?: Maybe<Scalars['Int']>;
};

export type ValidateIndividualInput = {
  assignmentCode: Scalars['String'];
  verification: Scalars['String'];
};

export type VerifyPatientInput = {
  id: Scalars['String'];
  token: Scalars['String'];
};

export type ZipFile = {
  __typename?: 'ZipFile';
  zipFile?: Maybe<Scalars['GraphQLBuffer']>;
};
