import { ApolloClient, createHttpLink, from, InMemoryCache } from '@apollo/client/core'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import { Observable } from '@apollo/client/utilities/observables/Observable'
import { provideApolloClient } from '@vue/apollo-composable'
import type { OperationDefinitionNode } from 'graphql/language'
import { storeToRefs } from 'pinia'

import { sharedClientOptions } from '@/apollo/clients/shared'
import { graphQlPath } from '@/config'
import {
  LoginDocument,
  LogoutPatientDocument,
  RefreshTokenDocument,
  useRefreshTokenMutation
} from '@/graphql/default/composables'
import { useUserStore } from '@/stores/user'
import { possibleTypes } from '~/@types/generated/default/fragmentTypes.json'
import { TokenType } from '~/@types/generated/default/graphql-schema-types'

export enum LocalStorageValues {
  AccessToken = 'SANADEUS_ACCESS_TOKEN'
}

const cache = new InMemoryCache({
  possibleTypes
})

const authLink = setContext((req, { headers }) => {
  const userStore = useUserStore()
  const { accessToken } = storeToRefs(userStore)
  return {
    headers: {
      ...headers,
      ...(accessToken &&
        req.operationName !== 'login' && { 'x-authorization': `Bearer ${accessToken.value}` })
    }
  }
})

const omittableOperations = (): string[] => {
  const operations = []
  const { name: login } = LoginDocument.definitions.find(
    (definition) => definition.kind === 'OperationDefinition'
  ) as OperationDefinitionNode

  if (login?.value) operations.push(login.value)

  const { name: logout } = LogoutPatientDocument.definitions.find(
    (definition) => definition.kind === 'OperationDefinition'
  ) as OperationDefinitionNode

  if (logout?.value) operations.push(logout.value)

  const { name: refresh } = RefreshTokenDocument.definitions.find(
    (definition) => definition.kind === 'OperationDefinition'
  ) as OperationDefinitionNode

  if (refresh?.value) operations.push(refresh.value)

  return operations
}

const errorLink = onError(({ forward, graphQLErrors, operation }) => {
  if (omittableOperations().includes(operation.operationName)) return
  if (graphQLErrors?.some((err) => err.extensions?.code === 'UNAUTHENTICATED')) {
    const userStore = useUserStore()
    const { setUserAccessToken } = userStore
    provideApolloClient(defaultClient)
    const { mutate } = useRefreshTokenMutation()
    return new Observable((subscriber) => {
      mutate({
        type: TokenType.Patient
      })
        .then((result) => {
          if (!result?.data) return
          const { refreshToken } = result.data
          if (refreshToken) {
            setUserAccessToken(refreshToken.accessToken)
            const oldHeaders = operation.getContext().headers
            operation.setContext({
              headers: {
                ...oldHeaders,
                'X-Authorization': `Bearer ${refreshToken.accessToken}`
              }
            })
          }
          if (subscriber.closed) return
          subscriber.next(!!refreshToken)
          subscriber.complete()
        })
        .catch((error) => {
          subscriber.error(error)
        })
    }).flatMap(() => forward(operation))
  }
})

const httpLink = createHttpLink({
  credentials: 'include',
  uri: `/${graphQlPath}`
})

export const defaultClient = new ApolloClient({
  ...sharedClientOptions,
  cache,
  link: from([authLink, errorLink, httpLink])
})
